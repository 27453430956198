import type { Commitment, TransactionInstruction } from '@solana/web3.js'
import { useWallet } from '@solana/wallet-adapter-vue'
import { Transaction } from '@solana/web3.js'
import { Notify } from 'quasar'
import {
  DEFAULT_CONFIRM_TIMEOUT,
  // DEFAULT_MONITOR_COMMITMENT,
  DEFAULT_SEND_TIMEOUT,
  TELEGRAM_ANNOUNCEMENT_URL,
} from '~/config'
import { useConnectionStore } from '~/store'

type MonitorTransactionParams = {
  onSuccess?: (signature: string) => void
  onError?: (reason: string) => void
  commitment?: Commitment
  sendTimeout?: number
  confirmTimeout?: number
  idx?: string
}

/**
 * Estimate the size of a created account based on the program ID of the instruction.
 * @param instruction The instruction to estimate the size for.
 * @returns The estimated size of the created account in bytes.
 */
async function estimateAccountSize(instruction: TransactionInstruction): Promise<number> {
  // Logic for various instructions, for example:
  // - If this is an instruction to create a token account, return 165 bytes.
  // - If this is an instruction to create metadata, return 512 bytes.
  const createAccountSizeMap: { [programId: string]: number } = {
    TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA: 165, // Token account
    TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb: 165, // Token account
    metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s: 512, // NFT metadata account
  }

  return createAccountSizeMap[instruction.programId.toBase58()] || 0
}

export function useMonitorTransaction() {
  const connectionStore = useConnectionStore()
  const { publicKey } = useWallet()
  const { t } = useTranslation()

  const cluster = toRef(connectionStore, 'cluster')
  const sending = ref(false)

  async function hasSufficientBalance(
    instructions,
  ) {
    try {
      const balanceLamports = await connectionStore.connection.getBalance(publicKey.value!)

      const transaction = new Transaction().add(...instructions)
      transaction.feePayer = publicKey.value!

      const { blockhash } = await connectionStore.connection.getLatestBlockhash()
      transaction.recentBlockhash = blockhash

      const message = transaction.compileMessage()
      const transactionFee = await connectionStore.connection.getFeeForMessage(message)

      let totalCostLamports = transactionFee.value ?? 0

      for (const instruction of instructions) {
        for (const accountMeta of instruction.keys) {
          if (accountMeta.isWritable && accountMeta.isSigner) {
            const accountInfo = await connectionStore.connection.getAccountInfo(accountMeta.pubkey)
            if (!accountInfo) {
              const requiredSize = await estimateAccountSize(instruction)
              const rentExemptBalance = await connectionStore.connection.getMinimumBalanceForRentExemption(requiredSize)
              totalCostLamports += rentExemptBalance
            }
          }
        }
      }

      const passes = Number(balanceLamports) >= Number(totalCostLamports)
      if (!passes) {
        throw new Error('Not enough funds for transaction')
      }
    } catch (error) {
      Notify.create({
        message: String(error),
        type: 'negative',
      })
    }
  }

  async function monitorTransaction(
    signatureOrPromise: Promise<string> | string,
    {
      onSuccess,
      onError,
      idx,
      commitment, // = 'confirmed',
      sendTimeout = DEFAULT_SEND_TIMEOUT,
      confirmTimeout = DEFAULT_CONFIRM_TIMEOUT,
    }: MonitorTransactionParams = {},
  ): Promise<void> {
    idx = idx ?? ''

    let dismiss = Notify.create({
      progress: true,
      type: 'ongoing',
      message: idx ? `Sending transaction "${idx}" ...` : 'Sending transaction...',
      timeout: sendTimeout,
    })

    sending.value = true

    const closeAction = {
      label: 'Close',
      color: 'white',
    }

    let signature = ''
    try {
      signature = String(await signatureOrPromise)
    } catch (error: any) {
      sending.value = false
      dismiss()
      if (error?.message && !String(error?.message).startsWith('User rejected')) {
        Notify.create({
          message: idx ? `Transaction "${idx}" error` : 'Transaction error',
          caption: error?.message,
          type: 'negative',
          timeout: 0,
          actions: [closeAction],
        })
      }
      return
    }

    // https://solscan.io/tx/{id}
    const explorerUrl = `https://explorer.solana.com/tx/${signature}?cluster=${cluster.value}`

    const exploreAction = {
      label: 'Explore',
      color: 'white',
      target: '_blank',
      href: explorerUrl,
      onClick: () => false,
    }

    const telegramAction = {
      label: 'Telegram',
      color: 'white',
      target: '_blank',
      href: TELEGRAM_ANNOUNCEMENT_URL,
    }

    try {
      dismiss()

      dismiss = Notify.create({
        // spinner: true,
        progress: true,
        type: 'ongoing',
        message: idx ? `Confirming transaction "${idx}" ...` : 'Confirming transaction...',
        actions: [exploreAction],
        timeout: confirmTimeout,
      })

      const latestBlockHash = await connectionStore.connection.getLatestBlockhash()
      const res = await connectionStore.connection.confirmTransaction({
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature,
      }, commitment)

      dismiss()

      if (res.value.err) {
        // console.error(res.value.err);
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(JSON.stringify(res.value.err))
      }

      dismiss = Notify.create({
        message: idx ? `Transaction "${idx}" confirmed` : 'Transaction confirmed',
        type: 'positive',
        actions: [exploreAction],
      })

      if (onSuccess) {
        onSuccess(signature)
      }
    } catch (error: any) {
      dismiss()
      let message = error.message
      let useHtml = false
      if (message.includes('block height exceeded')) {
        useHtml = true
        message = `<span>${message}</span><br><strong>${t('error.changeFee')}</strong>`
      }
      Notify.create({
        message: idx ? `Transaction "${idx}" error` : 'Transaction error',
        caption: message,
        html: useHtml,
        type: 'negative',
        timeout: 0,
        actions: [exploreAction, telegramAction, closeAction],
      })

      if (onError) {
        onError(error)
      }
      console.error(error)
    } finally {
      sending.value = false
    }
  }

  return {
    monitorTransaction,
    hasSufficientBalance,

    sending }
}
