import type { Cluster, Commitment } from '@solana/web3.js'
import { Connection, PublicKey } from '@solana/web3.js'
import { defineStore } from 'pinia'
import {
  DEFAULT_COMMITMENT,
  DEFAULT_CONFIRM_TIMEOUT,
  DEFAULT_ENDPOINT,
  ENDPOINTS,
} from '~/config'
import { tokenAuthFetchMiddleware } from '~/utils'

export type ExtendedCluster = Cluster | 'localnet'

export type Endpoint = {
  id: string
  name: string
  cluster: ExtendedCluster
  url: string
  stakePoolAddress: string
  stakeLimit?: number
  wsEndpoint?: string
  getToken?: () => Promise<string>
}

export const useConnectionStore = defineStore('connection', () => {
  const state = reactive({
    commitment: DEFAULT_COMMITMENT,
    confirmTransactionInitialTimeout: DEFAULT_CONFIRM_TIMEOUT,
  })

  const rpc = useLocalStorage<string>('rpc', '')

  const endpoint = computed(() => {
    return ENDPOINTS.find(e => e.id === rpc.value) ?? DEFAULT_ENDPOINT
  })

  const connection = computed<Connection>(() => {
    return new Connection(endpoint.value?.url, {
      confirmTransactionInitialTimeout: state.confirmTransactionInitialTimeout,
      wsEndpoint: endpoint.value?.wsEndpoint,
      commitment: state.commitment,
      fetchMiddleware: endpoint.value?.getToken
        ? tokenAuthFetchMiddleware({
            tokenExpiry: 5 * 60 * 1000, // 5 min
            getToken: endpoint.value?.getToken,
          })
        : undefined,
    })
  })

  const stakePoolAddress = computed<PublicKey | null>(() => {
    return endpoint.value ? new PublicKey(endpoint.value.stakePoolAddress) : null
  })

  const stakeLimit = computed<number>(() => {
    return endpoint.value?.stakeLimit ?? 0
  })

  const cluster = computed<ExtendedCluster>(() => {
    return endpoint.value?.cluster
  })

  const network = computed<string>(() => {
    const cluster = endpoint.value?.cluster
    return cluster === 'mainnet-beta' ? 'mainnet' : cluster
  })

  function setRpc(_rpc: string) {
    rpc.value = _rpc
  }

  function setCommitment(commitment: Commitment) {
    state.commitment = commitment
  }

  return {
    cluster,
    network,
    endpoint,
    stakeLimit,
    connection,
    stakePoolAddress,

    setRpc,
    setCommitment,
  }
})
